import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '66JfvYm8rkjHPqC6RraRnP',
  name: 'home',
  blocks: [
    {
      id: '4BSut5zC4GtzBakq5RVce9',
      type: 'Carousel',
      props: {
        technicalName: '[ES][HP] Top slider',
        slides: [
          {
            id: '2hfgvtMkydFo1ei0iKcxy8',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7n4QlpYKUVLdayc1npG66X/a19100725bbba5a0231eb74aee8b67ca/DN_HP-Banners_2_desktop_ES.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6o0JalaiVgt2tLgvtpBYRe/1a08f847c93a15510f5a1974bcd5ca07/DN_HP-Banners_2_mobile_ES.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'ES- iPhones x Downgrade Now',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '2H86FpCBM6yT5i0j5SLKts',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/98ts7rFk7Fm1pP2gzJfzV/0feaf1e4b8a8d5a6f1913e2e3684e0db/GENERIC_HPBANNERS_iPad2021_Desktop_ES.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3madupgHOKxXbWHcpmpbx5/9ac8c4ad03ce8d02578ef93ffb5299d2/GENERIC_HPBANNERS_iPad2021_Mobile_ES.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'ES - iPad x Generic',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/l/ipads/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '5CwP0yWRN018KjYAsJCS9D',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6B8nypobeX6vexMehAJIgQ/440672c6eb8ecd99acaebd115547176d/GENERIC_HPBANNERS_GalaxyS21_Desktop_ES.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5LQAwtm8bWlezRPl6MfS6T/7c901d012e8ba162417603a385066369/GENERIC_HPBANNERS_GalaxyS21_Mobile_ES.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'ES - Galaxy S21 x Generic',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/l/galaxy-s21/78beb19b-1cd7-4c8f-ad22-3ff303d70382',
            },
          },
          {
            id: '1nQFtX0mddONZ464a2kTw0',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2rtAR7zbNH5ScqlJtZ1M2t/d3807f8dd705c011ba43563546fb1913/GENERIC_HPBANNERS_MacBookAirM1_Desktop_ES.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/15157FyVYM4vadmruAnOpG/863a0046375ed500854cc45a510d7c5b/GENERIC_HPBANNERS_MacBookAirM1_Mobile_ES.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'ES - MacBook x Generic',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/l/generacion-macbook-m1/28f79b52-b5c8-4b2b-8325-e61d404808f0',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '1bY9ob2YlgfFMPbPG3VobW',
      type: 'SectionBanner',
      props: {
        technicalName: '[ES][HP] Value prop',
        title: 'Sácale partido a la tecnología con nosotros',
        description: 'Compra y vende tecnología que es mejor para el planeta.',
      },
      hiddenDevices: [],
    },
    {
      id: '6ihxrErOrhPYAaIGCcFGf1',
      type: 'Reinsurance',
      props: {
        technicalName: '[ES][HP] Value prop items',
        blocks: [
          {
            id: '4vyzSvVJaV5w8KBokJyb4b',
            title: 'Reacondicionado por profesionales',
            icon: 'IconOfficial',
            link: {
              href: 'https://backmarket.es/es-es/quality',
            },
            linkPosition: 'title',
          },
          {
            id: '5jHqsMFIGZ0mkJTijSeXi2',
            title: 'Hazte un renove',
            icon: 'IconPriceUpdate',
            link: {
              href: 'https://www.backmarket.es/es-es/buyback/home',
            },
            linkPosition: 'title',
          },
          {
            id: '3npoQbKn74XfixCyP9DZD3',
            title: 'Pago a plazos',
            icon: 'IconCreditCard',
            link: {
              href: 'https://help.backmarket.com/hc/es/articles/360026643854--Qu%C3%A9-m%C3%A9todos-de-pago-se-aceptan-en-Back-Market',
            },
            linkPosition: 'title',
          },
          {
            id: 'TXAwj0Y6a3fCWeaKsfESr',
            title: 'Exclusivo en la app',
            icon: 'IconSmartphone',
            link: {
              href: 'https://backmarket.onelink.me/j4lA/qpuj93ix',
            },
            linkPosition: 'title',
          },
        ],
        mobileScreenCols: 2.0,
        hasBackground: true,
        theme: 'white',
      },
      hiddenDevices: [],
    },
    {
      id: '1xx3OmpSpnbbCGMLHUnEdo',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[ES][HP] Most wanted categories',
        title: 'Compra los "más buscados"',
        size: 'small',
        cards: [
          {
            id: '2312QKDAQU5wSAiHrjJejb',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '1m9QMsPMhxuz7s3QAarB78',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES][DN] iPhone',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5QiFf3IKAPnyvmJAbKAkL4/1c913e302c3a1c4ebf5257ee126b1ac2/Phone_HP_Category_Smartphone_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/5pOFam9DGZq71N9Mjp6oq8/6e2a00e5071a2540e06b6773ec903aae/Phone_HP_Category_Smartphone_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPhones',
                      },
                      link: {
                        href: 'https://www.backmarket.es/es-es/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '1xpEND1XrxFgQ91syAl0uG',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPhone',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPhone',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '7mhA9UDrcyl48wetJivCrv',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '5VW5DIqYmeQgtPdkJjxia1',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES][DN] Macbook',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5GjoGJCqWVngJRShjjG7a/7512922aa0bd54c31753861b48a320ba/HP_Category_Laptop_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/4W39yhNXfMespBqgSIXmLQ/449f67d4d0541a08560f80f55304e10b/HP_Category_Laptop_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Macbook',
                      },
                      link: {
                        href: 'https://www.backmarket.es/es-es/l/macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '6b8Inq3fHAK5yuZhGEXv6F',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'Macbook',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'Macbook',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '6JIBy1a3XflUDHVaaEnUr2',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '2oWZBZpAE4yCH6hfDgMUIr',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES][DN] iPad',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/4hFoTgvuPtlS2xGUdtLsRy/01c1093d2aaa7f83f740574987e7f8fb/HP_Category_Tablet_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/2AmON8mdD9dHD2bp0521LV/f0b25d0de76eeff1d06443fc12578e5a/HP_Category_Tablet_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPad',
                      },
                      link: {
                        href: 'https://www.backmarket.es/es-es/l/ipads/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '7jP2DMfLbrTQTvVF4y3wwm',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPad',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPad',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '6DLBEX3UULaCnkIoOtV7YN',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '3lRiqAoK6Ou1YWaO5MA6sX',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES][DN] Console',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5Q6Fw32r8NELQcVLLxxqim/2a4d26bb2941ba6a3a49c640dcb02450/Phone_HP_Category_Gaming_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/7opuBWTtcbitW7A2tcaLHx/e1102a257f598dfe24118e6cc997a57a/Phone_HP_Category_Gaming_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Gaming console',
                      },
                      link: {
                        href: 'https://www.backmarket.es/es-es/l/consolas-y-videojuegos/0a092eda-8a90-4b60-85bd-8a3123b2fd84',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '6lxytyWRqGnH4lgpRe5E0F',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES] Consoles',
                      richText: {
                        nodeType: BLOCKS.DOCUMENT,
                        data: {},
                        content: [
                          {
                            nodeType: BLOCKS.HEADING_3,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: 'Consolas',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: BLOCKS.PARAGRAPH,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: '',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
        ],
        cardsHavePadding: false,
        layout: 'grid',
        mobileScreenColumns: 2,
      },
      hiddenDevices: [],
    },
    {
      id: '2A3v4PH12wE0ABKrcu0Pdn',
      type: 'Carousel',
      props: {
        technicalName: '[ES][HP] Content Slider',
        slides: [
          {
            id: '73kDUrGU68j8Kw2KJ43lp2',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2T9ErDOpAaJ1Hi58z9pzSn/3eb0ce232f1e36e5c0a44a64e48bcec3/Slider-3-desktop-ES-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5KVuOyciLi1EKK21i1dtVq/7978588d18ecb237d2ea3d2ded4de922/Slider-3-mobile-ES.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'What is Verified Refurbished',
            },
            link: {
              href: 'https://backmarket.es/es-es/quality',
            },
          },
          {
            id: '2QrMGDHw6QPImpt1qZfFtF',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4gSQe7IF303sjsH71XsWM3/b08e1bb73371896bf39c4178a8cc4493/Slider-1-desktop-ES-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3cvd6zNtIUXNXwW5gb0xqs/f30457820967d47b14c85f543c1f5da1/Slider-1-mobile-ES.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Refurbished, better than used',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/reacondicionado',
            },
          },
          {
            id: '6PKeOV268obVgLxKOp1VKl',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5XoWhUKhH4w8CoeR6VrKif/b615182ce08222105d94ed3b07811402/Slider-2-desktop-ES-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3SmRsumTmHSbe9QFD75EZL/46e161ab1b0a76859b4618ab93e25db2/Slider-2-mobile-ES.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Back Market is better than new',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '5Pid5NuOfVLxJXyYuxABQq',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/75ngmt4iPow0sUYPUCdP7b/4a27c55490a1de107182450e49a97595/Slider-4-desktop-ES-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4iilzbyxiYc1sXWAvg1BwT/bdb255af4b7782591916f8eae0482053/Slider-4-mobile-ES-CTA2.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: "We've prevented over 1 milion tons of CO2 emissions",
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/one-million',
            },
          },
          {
            id: '6D98Fg1STrWDKaA4TE1pZj',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5YDv2y0xq8QaRIp3v6iXpD/016e33c3d9de9c11c6ea02826a01c02d/Slider-5-desktop-ES-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4rQLaohmk9wFHk6ZfdAU9q/87a295f57434b6016ec0b58bc38a0a4b/Slider-5-mobile-ES.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '',
            },
          },
        ],
        isFullWidth: false,
      },
      hiddenDevices: [],
    },
    {
      id: '3HdYa0hrIuCKlgp5BL5OfA',
      type: 'SectionBanner',
      props: {
        technicalName: '[ES][HP] Sustainability value prop',
        title: 'La tecnología reacondicionada ayuda al planeta.',
        description: 'Creemos en un mundo que hace más con lo que ya tenemos.',
      },
      hiddenDevices: [],
    },
    {
      id: '7dvepyrqXkVyQHEzhss1bF',
      type: 'Media',
      props: {
        technicalName: '[ES][HP] Trade-in',
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/3DDzO9xDvFZ1GVyoHjpNwz/7d8261938923fcc44c18f2b9edadbb51/Desktop___ES.png',
          width: 2244,
          height: 648,
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/4JfaBEO8f6W2oboc4ShUBc/64a0a795645c8552ad5f4fe524272d7b/Mweb___ES.png',
          widthMobile: 780,
          heightMobile: 872,
          alt: 'trade-in',
        },
        link: {
          href: 'https://www.backmarket.es/es-es/buyback/home',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '1rbtiQVeMPb9xRRT7OmPeA',
      type: 'ArticleCardsList',
      props: {
        technicalName: '[ES][HP] Sustainability Articles',
        title: 'La tecnología reacondicionada ayuda al planeta',
        subtitle: '',
        articles: [
          {
            id: 'RxKOErzL2V27QnBrUjeCj',
            text: '¿De verdad Back Market reduce el daño medioambiental? Pues ahora está claro. Y todo gracias a cada vez más clientes que eligen tecnología renovada.',
            category: 'news',
            translatedCategory: 'Novedades',
            title: 'Un millón de gracias y ¡un millón de toneladas ahorradas!',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/23V6hKnxxtao8PgRQmRrNr/eb3800ac7ff5afd909c221c9b83d30cc/ES_-_Blog_Thumbnail_516x328.png',
              width: 516,
              height: 328,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Un millón de gracias y ¡un millón de toneladas ahorradas!',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/one-million',
            },
          },
          {
            id: '1k2AiuWMTV81DbexolawSd',
            text: 'Al comprar tecnología y dispositivos reacondicionados ahorras dinero, pero hay mucho más. Hoy tenemos evidencia científica de que los reacondicionados ayudan al planeta.',
            category: 'news',
            translatedCategory: 'Novedades',
            title:
              '¿Qué impacto tienen los dispositivos reacondicionados sobre el medio ambiente?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7a14081b5bc7480c7fda8b45319b0b9e-meta-526-276/c7f844ff004a64eb92077c3eb7110077/reacondicionado-vs-nuevo-banner.png',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '¿Qué impacto tienen los dispositivos reacondicionados sobre el medio ambiente?',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/impact-of-refurbished-on-environment',
            },
          },
          {
            id: '4zXpVNNUfacmSJ2v2MHI5L',
            text: 'Tanto en su producción como al final de sus vidas, nuestros móviles, ordenadores y otras joyas tecnológicas, tienen un impacto importante en el medio ambiente, algo que no siempre recordamos como consumidores habituales de estos dispositivos. La mitad de las emisiones de carbono del sector electrónico (4% de las emisiones mundiales) provienen de la fabricación y producción de estos aparatos. El reacondicionado, sin llegar a ser la solución perfecta, contribuye a disminuir el impacto ambiental de este sector. En este artículo os explicamos por qué.',
            category: 'news',
            translatedCategory: 'Novedades',
            title:
              '¿Nuevo o reacondicionado? El impacto medioambiental de la tecnología.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3703eb4b376487067b8381eb3b952212-meta-526-276/54e582972b3278f50a3f11b724877f05/ES_AaLaaJy.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '¿Nuevo o reacondicionado? El impacto medioambiental de la tecnología.',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '3FDXRKAiwWpnb5UHzN4Qjk',
            text: 'Reacondicionado, usado y de segunda mano: ¿cuáles son las diferencias?',
            category: 'news',
            translatedCategory: 'Novedades',
            title:
              '¿Cuál es la diferencia entre usado y reacondicionado en electrónica?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/8e2f056a35909a019eb83ee87fd4e2e1-meta-526-276/01da97335ca86599efbb12f716bb5b52/cambio-Copy_of_ES_-_Blog_Banners_-_A.png',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '¿Cuál es la diferencia entre usado y reacondicionado en electrónica?',
            },
            link: {
              href: 'https://www.backmarket.es/es-es/c/news/reacondicionado',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '29PqCAsrPzLFQo5iCubCvL',
      type: 'Faq',
      props: {
        technicalName: '[ES][HP] FAQ about services',
        title: 'Las preguntas del millón',
        subtitle: '',
        questions: [
          {
            id: '6huPsgQdOqVT7q2WX145sc',
            title:
              '¿Cómo puedo reciclar mi móvil antiguo o electrónicos que no uso?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Puedes hacer algo mejor: ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.es/es-es/buyback/smartphones',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'vende tu móvil',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ' o la electrónica que ya no te sirva haciéndote un renove.',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'text',
                      value: ' ',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: 'text',
                      value:
                        'Puedes obtener una oferta en minutos de un reacondicionador para vender lo que ya no te sirve. Para ponértelo aún más fácil, el envío de lo que quieras vender es gratis.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: 'VkP6NsT5ykLUhaXyCJCwp',
            title: '¿Puedo pagar a plazos?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Sí. Una gran ventaja de la tecnología reacondicionada es que puedes obtener la misma funcionalidad por hasta un 60% menos de precio que nuevo. Para hacer ese precio aún más accesible, ofrecemos ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://help.backmarket.com/hc/es/articles/360026643854--Qu%C3%A9-m%C3%A9todos-de-pago-se-aceptan-en-Back-Market',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'varias opciones de pago a plazos',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '56ylH0CGfFOYxIGsX9sFyh',
            title: '¿Puedo contratar un seguro para mi dispositivo?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Te cubrimos las espaldas para los accidentes menos graciosos de la vida... Nuestro seguro cubre caídas, derrames y más. Lo llamamos ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.es/es-es/e/backup',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'BackUp',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        '. Verás la opción de comprarlo junto al producto cuando estés pagando. Además, cuanto más tiempo puedas usar tu dispositivo, mejor será para el planeta.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'JSON_AND_HTML',
      },
      hiddenDevices: [],
    },
    {
      id: 'BuythtXt4YGvmYOTf3hqz',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[ES][HP] Press carousel',
        title: 'Visto en',
        size: 'small',
        cards: [
          {
            id: '54pnvuM0kqddzf2VN0K6FG',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '3Ot2FUrgiWznWrNK3KKnFU',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES] El economista',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/30eGyjuKQmP4EiPciprkfb/f5c4c147740c3b3563cab415c9b584f2/el_economista.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo El economista',
                      },
                      link: {
                        href: 'https://www.eleconomista.es/tecnologia/noticias/12871824/06/24/back-market-catapulta-sus-ventas-totales-hasta-los-2157-millones-en-2023.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '5FZqZuV2reJ4Bys8IwFqAj',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '4AsCuC4g2hjVkTckOwjMQH',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES] La Vanguardia',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/2i7wIYE1dZd2ZgIirzNrpX/a7bb049a34b699ec0816763c11987d59/la_vanguardia.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo La Vanguardia',
                      },
                      link: {
                        href: 'https://www.lavanguardia.com/dinero/20240612/9717403/moviles-viejos-reacondicionar-reciclar-reparar-telefono.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '4uXxLYWUMV9GirvMSMhB4f',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: 'vAW9uEmBynv5Ywl7vqHux',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES] RTVE',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/1bKMn7r1Pk1m26WXq6wqcI/23e20f2711e9b2a8b38df877eaf37443/rtve.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo RTVE',
                      },
                      link: {
                        href: 'https://www.rtve.es/noticias/20230302/mwc-2023-moviles-segunda-mano-auge/2429156.shtml',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '30rirBlFeQ1SANaYqUzFMM',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '21TIr2akBn0Ne1xQJdJ9Lh',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[ES] ABC Economia',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/7xSj2MCKOmvj67QfhuN1x4/b90f423ca48d484a4bb349107bff163c/abc_economia.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo ABC Economia',
                      },
                      link: {
                        href: 'https://www.abc.es/economia/tecnologia-reacondicionada-conecta-fondo-espana-20240908054941-nt.html?ref=https%3A%2F%2Fwww.abc.es%2Feconomia%2Ftecnologia-reacondicionada-conecta-fondo-espana-20240908054941-nt.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
        ],
        cardsHavePadding: true,
        layout: 'carousel',
        mobileScreenColumns: 1,
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Productos reacondicionados y mejores para el planeta | Back Market',
  meta: {
    title:
      'Back Market: La electrónica reacondicionada que es mejor para el planeta',
    description:
      'Tecnología reacondicionada al mejor precio: móviles, tablets, portátiles y lo último en electrónica, con 2 años de garantía y 30 días de prueba.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/6404639e481c862745c1dcc9dd4ea7ef/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Productos reacondicionados y mejores para el planeta',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home',
}

export default HOME_FALLBACK_DATA
